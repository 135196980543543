<template>
	<div>
		<div>
			<multiselect v-model="selected"
			             label="text"
			             track-by="value"
			             :placeholder="$t('search')"
			             open-direction="bottom"
			             :options="options"
			             :searchable="true"
			             :loading="isLoading"
			             :internal-search="false"
			             :close-on-select="true"
			             :show-no-results="true"
			             :select-label="''"
			             :selected-label="''"
			             :deselect-label="''"
			             @search-change="search"
			             @input="handleInput($event)"
                         @select="select"
			             :class="getClass"
			>
				<span slot="noOptions"></span>
				<span slot="noResult">{{ $t('no_result') }}</span>
			</multiselect>
		</div>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>
<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Service
	import CourseService from "@/services/CourseService";

	export default {
		props: {
			value: {
				type: Number,
				default: null
			},
			valueObject: {
				type: Object,
				default: null
			},
			validateError: {
				type: String,
				default: null
			}
		},
		computed: {
			getClass() {
				let selectClass = '';
				if (this.validateError) {
					selectClass = 'box-border-color';
				}

				return selectClass;
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
			valueObject: {
				deep: true,
				handler: function (newValue, oldValue) {
					if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
						if(newValue.value && newValue.text){
							this.options.push(newValue);
						}
						this.selected = this.setSelected(this.value, this.options)
					}
				}
			},
		},
		data() {
			return {
				isLoading: false,
				options: [],
				selected: null,
			}
		},
		created() {
			if (this.valueObject) {
				if(this.valueObject.value && this.valueObject.text) {
					this.options.push(this.valueObject);
				}
				this.selected = this.setSelected(this.value, this.options)
			}
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
            select(val){
                this.$emit('courseSelected', val)
            },
			search(key) {
				if (key.length > 2) {
					const config = {
						params: {
							key: key,
							sort: this.$i18n.locale == 'tr' ? 'name' : 'name_en',
						}
					}
					CourseService.search(config)
					             .then((response) => {
						             this.options = [];
						             let data = response.data.data.filter(function (x){
                                         return x.code[0]=="C"&&x.code[1]=="O" && x.code[2]=="O" && x.code[3]=="P"
                                     });
						             data.forEach((item) => {
							             this.options.push({
								             "value": item.id,
								             "text": item.code + ' - ' + this.getLocaleText(item, 'name'),
                                             "item": item
							             });
						             });

					             })
					             .then(() => {
						             this.selected = this.setSelected(this.value, this.options)
					             })
				}
			}
		}
	}
</script>

